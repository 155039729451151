import './App.css';
import HomePage from './Pages/HomePage';

function App() {


  return (
    <div style={{ overflow: "hidden" }}>
      <HomePage />

    </div>
  );
}

export default App;
