import "./Searchbar.css";

import { SearchBarType } from "./type";

const Searchbar = ({
    onClick,
    width,
    marginRight,
    setSearchText,
    searchText,
    onKeyUp,
    errorMessage

}: SearchBarType) => {
    function onlyLettersAndSpaces(str: string) {
        // return Boolean(str?.match(/^[A-Za-z\s]*$/));
        return Boolean(
            str?.match(/^[A-Za-z0-9ÇçĞğİıÖöŞşÜü][A-Za-z0-9ÇçĞğİıÖöŞşÜü\s]*$/),
        );
    }

    const validateAndSetTextChange = (text: string) => {
        const isValid = onlyLettersAndSpaces(text);
        if (text === '' || isValid) {
            setSearchText(text)
        }
    };
    return (
        <div className="search-box" style={{ width: width, marginRight: marginRight, }}>
            <div style={{ display: "flex" }}>
                <input minLength={2} type="text" className="search-input" placeholder="Neyi en ucuza ikinci el almak istersin?" value={searchText} onChange={(event) => validateAndSetTextChange(event.target.value)} onKeyUp={onKeyUp} />

                <button type="submit" className="search-button" onClick={onClick}>
                    Ara
                </button>
            </div>
            <div className="error-message-div" >{errorMessage}</div>

        </div>
    );
};

export { Searchbar };
