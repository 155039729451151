import { useCallback, useEffect, useId, useState } from 'react';
import { Card } from '../components/card/Card';
import { Searchbar } from '../components/searchbar/Searchbar';

import { useSocket } from '../hook/useSocket';
import { ProductListResponseModel, ProductMapper, ProductViewModel } from '../models';

import InfiniteScroll from 'react-infinite-scroll-component';
import Adsense from '../components/google-ads/GoogleAds';

const defaultSearchPageCss = {
  paddingBottom: '50px !important',
  flexDirection: 'column',
  searchbarWidth: '580px',
  justifyContent: 'space-between',
  marginBottomLogo: '25px',
  homeContainerjustify: 'center',
  p10: '0px',
  background: 'transparent',
  searchbarMarginRight: 'marginRight: 50px',
  ml50: '0px',
  display: 'none',
  logoHeight: '60px',
  homaPageJustifyContent: 'center',
  middleAreaPadding: 'padding: 25px 50px 25px 50px',
};

const detailPageCss = {
  paddingBottom: '50px',
  flexDirection: 'row',
  searchbarWidth: '33%',
  justifyContent: 'space-between',
  marginBottomLogo: '20px',
  homeContainerjustify: 'flex-start',
  p10: '20px 50px 10px 0px;',
  background: 'white',
  searchbarMarginRight: '50px',
  ml50: '50px',
  display: 'flex',
  logoHeight: '40px',
  homaPageJustifyContent: 'center',
  middleAreaPadding: 'padding: 25px 50px 25px 50px',
};

function HomePage() {
  const [data, setData] = useState<ProductViewModel[]>([]);
  const [skeletonData, setSkeletonData] = useState<ProductViewModel[]>(
    new Array(10).fill(
      () =>
        ({
          productDescription: '',
          productImage: '',
          productLink: '',
          productName: '',
          productPrice: '',
          typeLink: '',
          typeName: '',
        } as ProductViewModel),
    ),
  );

  const [css, setCss] = useState(defaultSearchPageCss);
  const [listItem, setListItem] = useState(false);
  const [loading, setLoading] = useState(false);
  const [t, setT] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(20);
  const [orderType] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');

  const dataSetter = useCallback(
    (_datas: ProductViewModel[]) => {
      const products = pageIndex > 0 ? [...data, ..._datas] : _datas;
      setData(products);
    },
    [data, pageIndex],
  );

  const onMessage = useCallback(
    (message: string) => {
      const productListResponseModel = JSON.parse(message) as ProductListResponseModel;

      const products = ProductMapper.convertArray(productListResponseModel.p);

      // const products = datas;

      setTimeout(() => {
        dataSetter(products);
        setLoading(false);
      }, 300);
    },
    [dataSetter],
  );
  const { connect, retryConnect, wsManager, isClosed } = useSocket<string>({
    onMessage: onMessage,
  });

  useEffect(() => {
    connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    if (searchText.length > 2) {
      const searchAct = () => {
        const requestModel = {
          t,
          m: searchText,
          pi: 0,
          ps: pageSize,
          ot: orderType,
        };
        setPageIndex(0);
        setLoading(true);
        const message = JSON.stringify(requestModel);
        wsManager?.send(message);
        setListItem(true);
        setCss(detailPageCss);
        setErrorMessage('');
      };
      if (isClosed) {
        retryConnect(searchAct);
      } else {
        searchAct();
      }
    } else {
      setErrorMessage('Lütfen en az 3 harf giriniz.');
    }
  };

  const handleLoadOtherProducts = () => {
    const searchAct = () => {
      const requestModel = {
        t,
        m: searchText,
        pi: pageIndex + 1,
        ps: pageSize,
        ot: orderType,
      };
      setPageIndex(pageIndex + 1);
      setLoading(true);
      const message = JSON.stringify(requestModel);
      wsManager?.send(message);
      setListItem(true);
      setCss(detailPageCss);
    };

    if (isClosed) {
      retryConnect(searchAct);
    } else {
      searchAct();
    }
  };
  const backToHomaPage = () => {
    setCss(defaultSearchPageCss);
    setListItem(false);
    setSearchText('');
  };

  const onKeyUp = (e: any) => {
    if (e.key === 'Enter') {
      setTimeout(() => {
        onSearch();
      }, 30);
    }
  };

  return (
    <>
      <div
        className="home-page-container"
        style={{ justifyContent: `${css.homeContainerjustify} ` }}
      >
        <div
          className="middle-area"
          //@ts-ignore
          style={{
            // paddingBottom: css.paddingBottom,
            width: '100%',
            //@ts-ignore
            flexDirection: css.flexDirection,
            justifyContent: `${css.justifyContent}`,
            // padding: css.p10,
            background: css.background,
            padding: css.middleAreaPadding,
          }}
        >
          <div
            className="bitpazari-logo pointer"
            style={{
              marginBottom: css.marginBottomLogo,
              // marginLeft: css.ml50,
              width: 225,
              height: css.logoHeight,
            }}
            onClick={backToHomaPage}
          ></div>

          <Searchbar
            onClick={() => onSearch()}
            width={css.searchbarWidth}
            marginRight={css.searchbarMarginRight}
            setSearchText={setSearchText}
            searchText={searchText}
            onKeyUp={onKeyUp}
            errorMessage={errorMessage}
          />
        </div>

        <div
          className="row middle-area-row"
          style={{
            overflow: 'hidden scroll',
            width: '100%',
            height: '100%',
            display: css.display,
            flexDirection: 'row',
            gap: 20,
          }}
        >
          <>
            {listItem && (
              <>
                <div
                  id="scrollableDiv"
                  style={{ height: 'calc(100vh - 135px)', overflow: 'scroll' }}
                >
                  <InfiniteScroll
                    dataLength={data.length}
                    next={handleLoadOtherProducts}
                    hasMore={true}
                    loader={<div style={{ display: 'none' }}> Yükleniyor</div>}
                    endMessage={<div style={{ display: 'none' }}>Hepsi Yüklendi</div>}
                    scrollableTarget="scrollableDiv"
                  >
                    {data?.map((item, index) => (
                      <Card data={item} key={item.productName + '_' + index} />
                    ))}

                    {loading &&
                      skeletonData.map((dt, index) => {
                        return <Card data={dt} isSkeleton={true} key={index} />;
                      })}
                  </InfiniteScroll>
                </div>
              </>
            )}
          </>
        </div>
      </div>

      {listItem && (
        <Adsense
          layoutKey="-6t+ed+2i-1n-4w"
          client="ca-pub-4151653621783246"
          slot="8134570884"
          layout="in-article"
          format="fluid"
          responsive="true"
        />
      )}
      <div style={{ textAlign: 'center' }}>
        Made with ❤️ by{' '}
        <a
          href="https://www.lenasoftware.com"
          target={'_blank'}
          style={{ textDecoration: 'none', color: '#004FF rel="noreferrer"F' }}
          rel="noreferrer"
        >
          Lena Software
        </a>
      </div>
    </>
  );
}

export default HomePage;
