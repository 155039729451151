import { useEffect } from "react";
import "./Card.css";
import { CardTypes } from "./type";

const Card = ({ data, isSkeleton = false }: CardTypes) => {
	// typeName e tıklayınca gidilecek adresi productLink

	useEffect(() => {
	}, [isSkeleton])

	// if (isSkeleton) {
	// 	return (
	// 		<div className="card-container col-auto placeholder-glow">
	// 			<div className="top-side placeholder">
	// 				<img
	// 					src={data?.productImage}
	// 					alt={data?.productName}
	// 					className="resim placeholder"
	// 				/>
	// 			</div>
	// 			<div className="middle-side placeholder-glow">
	// 				<div className="card-titles placeholder">
	// 					{data?.productName ?? "-"}
	// 				</div>
	// 				<div className="website-price-box  placeholder-glow">
	// 					<div className="website  placeholder">{data?.typeName}</div>
	// 					<div className="price  placeholder">{data?.productPrice}</div>
	// 				</div>
	// 			</div>
	// 			<div className="bottom-side  placeholder-glow">
	// 				<button className="go-to-page placeholder">İlana Git</button>
	// 			</div>
	// 		</div>
	// 	);
	// }
	return (
		<div className="card-container">
			<div className={`top-side  ${isSkeleton ? "placeholder-grow" : ""}`}>

				<a className={`resim ${isSkeleton ? "placeholder" : ""}`} href={data?.productLink} target={"_blank"} ><img
					src={data?.productImage}
					alt={data?.productName}
					className={`resim ${isSkeleton ? "placeholder" : ""}`}
				/></a>
			</div>
			<div className="middle-side placeholder-glow">
				<div className={`card-titles  ${isSkeleton ? "placeholder" : ""}`}>{data?.productName}</div>
				<div className={`website-price-box placeholder-glow`}>
					<div className={`website  ${isSkeleton ? "placeholder" : ""}`}>{data?.typeName}</div>
					<div className={`price  ${isSkeleton ? "placeholder" : ""}`}>{data?.productPrice && data?.productPrice + " TL"} </div>
				</div>
			</div>
			<div className="bottom-side placeholder-grow">
				<a className={`${isSkeleton ? "placeholder" : ""}`} href={data?.productLink} target={"_blank"} ><button className={`go-to-page`}>İlana Git</button></a>
			</div>
		</div>
	);



};

export { Card };
