import { TypedMapper } from "typed-mapper";
import {
	ProductResponseModel,
	ProductListResponseModel,
} from "./ProductResponseModel";
import { ProductViewModel } from "./ProductViewModel";
import { SearchRequestModel } from "./SearchRequestModel";

const ProductMapper = TypedMapper.map<ProductResponseModel, ProductViewModel>({
	productDescription: "pd",
	productImage: "pi",
	productLink: "pl",
	productName: "pn",
	productPrice: "pp",
	typeLink: "tl",
	typeName: "tn",
});

export { ProductMapper };

export type {
	ProductResponseModel,
	ProductViewModel,
	SearchRequestModel,
	ProductListResponseModel,
};
