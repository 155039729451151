import { useCallback, useRef, useState } from "react";

const SOCKET = "wss://ws.bitpazari.app/search";


export type UseWebSocketProps<T> = {
	onConnect?: () => void;
	onClose?: () => void;
	onError?: (error: unknown) => void;
	onMessage?: (tData: T) => void;
};

function useSocket<T>(props: UseWebSocketProps<T>) {
	const { onMessage, onConnect, onError, onClose } = props;
	const wsManager = useRef<WebSocket | null>(null);
	const retryCallbackRef = useRef<null | undefined | Function>(null);
	const [status, setStatus] = useState<number | null>(
		wsManager.current?.readyState || null
	);
	const [error, setError] = useState<string | null>();

	const isConnecting = status === WebSocket.CONNECTING;
	const isConnected = status === WebSocket.OPEN;
	const isClosed = status === WebSocket.CLOSED;

	if (wsManager.current) {

		wsManager.current.onopen = () => {
			setStatus(WebSocket.OPEN);
			onConnect?.();
			if (retryCallbackRef.current) {
				retryCallbackRef.current?.();
				retryCallbackRef.current = null;
			}
		};

		wsManager.current.onmessage = (e: MessageEvent) => {
			try {
				onMessage?.(e.data);
				// eslint-disable-next-line no-catch-shadow
			} catch (error) {
			}
		};

		// @ts-ignore
		wsManager.current.onerror = (e: ErrorEvent) => {
			setError(e.message);
			setStatus(WebSocket.CLOSING);
			onError?.(e);
		};

		wsManager.current.onclose = (e: CloseEvent) => {
			setStatus(WebSocket.CLOSED);
			wsManager.current = null;
			onClose?.();
		};
	}

	const connect = useCallback(() => {
		if (!wsManager.current) {
			setStatus(WebSocket.CONNECTING);
			wsManager.current = new WebSocket(SOCKET);
		}
	}, []);

	const retryConnect = useCallback(
		(retryCallback?: () => void) => {
			connect();
			retryCallbackRef.current = retryCallback;
		},
		[connect]
	);

	return {
		status,
		error,
		wsManager: wsManager.current,
		isClosed,
		isConnected,
		isConnecting,
		connect,
		retryConnect,
	};
}

export { useSocket };

export { };
